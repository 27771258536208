@font-face {
  font-family: 'Anta';
  src: local('Anta Regular'), local('Anta-Regular'),
      url('../fonts/Anta-Regular.woff2') format('woff2'),
      url('../fonts/Anta-Regular.woff') format('woff'),
      url('../fonts/Anta-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body, html {
  position: relative;
  height: 100%;
  width: 100%;
  color: #d3dbed;
  background-color: #1d2630;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Anta', 'Arial', 'Helvetica', sans-serif;
}
h1 {
  color: #9de712;
}
h2 {
  background: #2f3a45;
  padding: .15em .5em;
  margin: 0;
  color: #9de712;
}
a {
  color: #9de712;
  text-decoration: none;
  transition: color .2s ease-in-out;
}
a:hover {
  color: #ff5976;
  text-decoration: none;
}
.linktree .linktreelink img,
.linktree .linktreelink svg {
  width: 100%;
  display: block;
  margin-bottom: 1em;
}

.linktree .linktreelink {
  display: block;
  background-color: #26303b;
  border-color: #141921;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  color: #d3dbed;
  padding: 1em;
  border-radius: 3px;
  height: 100%;
  font-family: 'Anta', 'Arial', 'Helvetica', sans-serif;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
}
.linktree > .row > div {
  margin-bottom: 1em;
}
.linktree .linktreelink svg linearGradient stop {
  transition: stop-color .2s ease-in-out;
}
.linktree .linktreelink circle,
.linktree .linktreelink path {
  transition: fill .2s ease-in-out;
}
.linktree .linktreelink:not(:hover) circle,
.linktree .linktreelink:not(:hover) path {
  fill: #ffffff;
}
.linktree .linktreelink:not(:hover) path.cbradio-fill {
  fill: #26303b;
}
.linktree .linktreelink img {
  filter: grayscale(100%);
  transition: filter .2s ease-in-out;
}
.linktree .linktreelink:hover img {
  filter: grayscale(0);
}
.linktree .linktreelink:not(:hover) svg linearGradient stop {
  stop-color: #ffffff!important;
}
.linktree .linktreelink:not(:hover) #icon-truskoolbreakz-bg,
.linktree .linktreelink:not(:hover) #icon-facebook-white {
  fill: #26303b;
}
.linktree .linktreelink:hover {
  background-color: #141921;
  border-color: #26303b;
  color: #9de712;
}

.footer {
  background-color: #141921;
}
#impressum {
  text-align: left;
}